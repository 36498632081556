<ng-container
  *ngTemplateOutlet="hasPrepaidFeature ? prepaidWalletTemplate : normalWalletTemplate"
></ng-container>

<ng-template #prepaidWalletTemplate>
  <div class="wrapper">
    <div class="wrapper__section-flag">
      <div class="wrapper__section-flag__pierce">
        <div class="wrapper__section-flag__pierce__inner"></div>
      </div>
      <img [src]="selectedCountry.flag" class="country-profit-section__flag-icon" alt="" />
    </div>
    <div class="wrapper__section-buttons caption1--bold">
      <div
        class="wrapper__section-buttons__button {{ walletView === mode.key ? 'active' : '' }}"
        *ngFor="let mode of walletViewModes"
        (click)="toggleWalletView(mode.key)"
      >
        {{ mode.label | translate }}
      </div>
      <div
        class="wrapper__section-buttons__slide {{ direction }} {{
          modeChanged ? (walletView === 'have' ? 'have' : 'owe') : ''
        }}"
      ></div>
    </div>
    <div class="wrapper__section-balance">
      <div class="wrapper__section-balance__description caption2--bold">
        {{
          (walletView === 'have'
            ? 'WALLET.WALLET_CARD.PREPAID.BALANCE.DESCRIPTION.HAVE'
            : 'WALLET.WALLET_CARD.PREPAID.BALANCE.DESCRIPTION.OWE'
          ) | translate : { country: countryName }
        }}
      </div>
      <div class="wrapper__section-balance__value {{ walletView }} heading2--bold">
        {{
          walletView === 'have' ? walletData.eligibleAmount : walletData.amountsOwedToTaager || 0
        }}
        {{ currency | currencyTranslate | translate | currencyShortName }}
      </div>
    </div>
    <div
      *ngIf="walletView === 'have'"
      class="wrapper__section-action-button body2--bold"
      (click)="onWithdrawClicked()"
    >
      {{ 'WALLET.WALLET_CARD.PREPAID.ACTION_BUTTON' | translate }}
    </div>
    <div class="wrapper__section-divider"></div>
    <div class="wrapper__section-expected-profits">
      <div class="wrapper__section-expected-profits__description caption2--bold">
        {{ 'WALLET.WALLET_CARD.PREPAID.PROFITS.DESCRIPTION' | translate }}
      </div>
      <div class="wrapper__section-expected-profits__meta">
        <div class="wrapper__section-expected-profits__meta__profit heading2--bold">
          {{ walletData.expectedAmount }}
          {{ currency | currencyTranslate | translate | currencyShortName }}
        </div>
        <div class="wrapper__section-expected-profits__meta__description caption2--bold">
          {{
            walletData.ordersCountForExpectedAmount === 1
              ? ('WALLET.WALLET_CARD.PREPAID.PROFITS.META.DESCRIPTION_ONE' | translate)
              : ('WALLET.WALLET_CARD.PREPAID.PROFITS.META.DESCRIPTION_MULTIPLE'
                | translate : { orders: walletData.ordersCountForExpectedAmount })
          }}
        </div>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #normalWalletTemplate>
  <div *ngIf="!isLoading" class="wallet-card-wrapper normal">
    <section class="country-profit-section">
      <div class="country-profit-section__flag-wrapper">
        <img [src]="selectedCountry.flag" class="country-profit-section__flag-icon" alt="" />
      </div>
      <p class="country-profit-section__eligible-profit">
        {{ 'WALLET.WALLET_CARD.TITLE' | translate }}
        <br />
        <span class="country-profit-section__eligible-profit-value">{{
          walletData.eligibleAmount
        }}</span>
        <span class="country-profit-section__eligible-profit-currency">{{
          currency | currencyTranslate | translate
        }}</span>
      </p>
      <div
        class="country-profit-section__locked-amount"
        *ngIf="
          totalLockedAmount > 0 &&
          walletData.currency === saudiCurrency &&
          preOrderExperimentationAllowed
        "
      >
        <div class="country-profit-section__locked-amount__icon">
          <img src="assets/img/wallet-icons/lock-green.svg" alt="" />
        </div>
        <div class="country-profit-section__locked-amount__text-amount">
          <p class="country-profit-section__locked-amount__text-amount__text">
            {{ 'WALLET.WALLET_CARD.LOCKED.TITLE' | translate }}
          </p>
          <p class="country-profit-section__locked-amount__text-amount__bold-currency">
            <span> {{ totalLockedAmount }} </span>
            {{ currency | currencyTranslate | translate | currencyShortName }}
          </p>
        </div>
        <div class="div__info-tooltip">
          <p class="tooltip-title">
            {{ 'WALLET.WALLET_CARD.LOCKED.TOOLTIP_TITLE' | translate }}
          </p>
          <p class="tooltip-text">
            {{ 'WALLET.WALLET_CARD.LOCKED.TOOLTIP_DESCRIPTION_1' | translate }}
          </p>
          <span class="remaining-orders">{{
            'WALLET.WALLET_CARD.LOCKED.TOOLTIP_DESCRIPTION_2' | translate
          }}</span>
        </div>
      </div>
    </section>
    <div *ngIf="isDuplicateAccount" class="duplicate-account">
      <img src="assets/img/product-details-icons/lock.svg" alt="" />
      {{ trans('wallet.duplicateAccountError') }}
    </div>
    <button
      *ngIf="!isDuplicateAccount"
      class="withdraw-request-button"
      (click)="onWithdrawClicked()"
      [disabled]="isWithdrawalsDisabled"
      [ngClass]="{
        'disabled-button': isWithdrawalsDisabled
      }"
    >
      {{ trans('WALLET.WITHDRAWALS.WITHDRAW_PROFIT') }}
    </button>
    <section class="in-progress-profit-section">
      <img
        loading="lazy"
        src="assets/img/wallet-icons/money.svg"
        class="in-progress-profit-section__icon"
        alt=""
      />
      <p class="in-progress-profit-section__in-progress-profit">
        {{ trans('WALLET.WALLET_CARD.EXPECTED_PROFIT') }}
        <br />
        <span class="in-progress-profit-section__in-progress-profit-value"
          >{{ walletData.expectedAmount }}
        </span>
        <span class="in-progress-profit-section__in-progress-profit-currency">{{
          currency | currencyTranslate | translate
        }}</span>
      </p>
      <p class="in-progress-profit-section__in-progress-orders">
        {{ walletData.ordersCountForExpectedAmount }}
        {{ 'WALLET.WALLET_CARD.ORDERS' | translate }}
      </p>
    </section>
  </div>
</ng-template>
