import { AsyncPipe, NgClass, NgFor, NgIf, NgStyle, NgTemplateOutlet } from '@angular/common';
import { Component, EventEmitter, inject, Input, OnInit, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { TranslateModule } from '@ngx-translate/core';
import { kycPopupAtom } from '@presentation/kyc/atoms';
import { WalletModel } from 'src/app/core/domain/wallet/wallet.model';
import { GetBulkPreOrderRequestsUseCase } from 'src/app/core/usecases/bulk-preorder/get-bulk-pre-order-requests.usecase';
import { GetFeatureFlagUsecase } from 'src/app/core/usecases/get-feature-flag.usecase';
import { CheckUserFeatureExistsUseCase } from 'src/app/core/usecases/user/check-user-feature-exists.usecase';
import { Country } from 'src/app/presentation/shared/interfaces/countries';
import { MultitenancyService } from 'src/app/presentation/shared/services/multitenancy.service';
import { LocalizedComponent } from '../../base/localized.component';
import { CURRENCIES, FEATURE_FLAGS, PREPAID_ORDERS } from '../../shared/constants';
import { CurrencyShortNamePipe } from '../../shared/pipes/currency-short-name.pipe';
import { CurrencyTranslatePipe } from '../../shared/pipes/currency-translate.pipe';
import { SharedOverlayService } from '../../shared/services/shared-overlay.service';
import { SiteTranslateService } from '../../shared/services/translate.service';
import { UserResolutionService } from '../../shared/services/user-resolution.service';
import { translateInstantUtility } from '../../shared/utilities/translate-instant.utility';
import { WithdrawalDialogComponent } from '../withdrawal-dialog/withdrawal-dialog.component';

type WalletView = 'have' | 'owe';

@Component({
  selector: 'app-wallet-card',
  templateUrl: './wallet-card.component.html',
  styleUrls: ['./wallet-card.component.scss'],
  standalone: true,
  imports: [
    NgIf,
    NgClass,
    AsyncPipe,
    TranslateModule,
    CurrencyShortNamePipe,
    CurrencyTranslatePipe,
    NgTemplateOutlet,
    NgStyle,
    NgFor,
  ],
})
export class WalletCardComponent extends LocalizedComponent implements OnInit {
  @Input() walletData: WalletModel;

  @Input() isWithdrawalsDisabled: boolean;

  @Input() isDuplicateAccount: boolean;

  @Input() shouldPromptToVerifyId: boolean;

  @Output() withdrawalRequested: EventEmitter<void> = new EventEmitter();

  public selectedCountry: Country;

  public currency: string;

  public isLoading: boolean;

  public saudiCurrency = CURRENCIES.SAR;

  public preOrderExperimentationAllowed = false;

  public totalLockedAmount = 0;

  public hasPrepaidFeature = false;

  public translateInstant = translateInstantUtility();

  public walletView: WalletView;

  public walletViewModes: Array<{ key: WalletView; label: string }>;

  public modeChanged = false;

  public countryName: string;

  private _siteTranslateService: SiteTranslateService = inject(SiteTranslateService);

  private _countryMapping: { [key: string]: string } = {
    Egypt: 'COUNTRY.EGYPT',
    'Kingdom of Saudi Arabia': 'COUNTRY.KINGDOM_OF_SAUDI_ARABIA',
    'United Arab Emirates': 'COUNTRY.UAE',
    Testing: 'Testing',
    Iraq: 'COUNTRY.IRAQ',
  };

  constructor(
    private _multitenancyService: MultitenancyService,
    private _dialog: MatDialog,
    private _getBulkPreOrderRequestsUseCase: GetBulkPreOrderRequestsUseCase,
    private _userResolutionService: UserResolutionService,
    private _getFeatureFlagUsecase: GetFeatureFlagUsecase,
    private _sharedOverlayService: SharedOverlayService,
    private _checkUserFeatureExistsUseCase: CheckUserFeatureExistsUseCase,
  ) {
    super();
    this.walletView = 'have';
    this.walletViewModes = [
      { key: 'have', label: 'WALLET.WALLET_CARD.PREPAID.BUTTONS.HAVE' },
      { key: 'owe', label: 'WALLET.WALLET_CARD.PREPAID.BUTTONS.OWE' },
    ];
  }

  ngOnInit(): void {
    this.isLoading = true;

    this.hasPrepaidFeature = this._checkUserFeatureExistsUseCase.execute(PREPAID_ORDERS);
    this._multitenancyService.getSupportedCountries().then((countries) => {
      this.selectedCountry = countries.find(
        (country) => country.currency.englishName === this.walletData.currency,
      )!;

      this.currency = this.selectedCountry?.currency.arabicName;
      this.isLoading = false;
      this._setCountryTranslateKey();
      this._listenForLanguageChange();
    });
    this._getFeatureFlagUsecase.execute(FEATURE_FLAGS.BULK_PRE_ORDERS_EXPERIMENT).subscribe({
      next: (bulkPreorderExpirementFeatureFlag) => {
        if (bulkPreorderExpirementFeatureFlag) {
          this.preOrderExperimentationAllowed = true;
          this._getBulkPreOrderRequestsUseCase.execute().subscribe({
            next: (requests) => {
              this.totalLockedAmount = requests
                .map((request) => request.lockedWalletAmount)
                .reduce(
                  (partialTotalAmount, currentAmount) => partialTotalAmount + currentAmount,
                  0,
                );
            },
          });
        }
      },
    });
  }

  private _listenForLanguageChange(): void {
    this._siteTranslateService.onLanguageChange().subscribe({
      next: () => {
        this._setCountryTranslateKey();
      },
    });
  }

  /**
   * Sets the translated country name based on the selected country's English name.
   * If the country key is not found in the mapping, logs an error and falls back to the original name.
   */
  private _setCountryTranslateKey(): void {
    const countryKey = this._countryMapping[this.selectedCountry.englishName];
    if (!countryKey) {
      this.countryName = this.selectedCountry.englishName; // Fallback to the original name if no mapping exists
    } else {
      try {
        this.countryName = this.translateInstant(countryKey);
      } catch (error) {
        this.countryName = this.selectedCountry.englishName; // Fallback in case of error
      }
    }
  }

  public toggleWalletView(view: WalletView): void {
    if (this.walletView !== view) {
      this.modeChanged = true;
    }
    this.walletView = view;
  }

  onWithdrawClicked(): void {
    if (!this._userResolutionService.analyzeUserToken().canMakeWithdrawal) {
      return this._openVerificationDialog();
    }

    if (this.shouldPromptToVerifyId) {
      return kycPopupAtom.open();
    }

    this._openWithdrawalDialog();
  }

  private _openVerificationDialog(): void {
    this._sharedOverlayService.setOverlayType('pleaseVerify');
  }

  private _openWithdrawalDialog(): void {
    const dialogRefOrderChat = this._dialog.open(WithdrawalDialogComponent, {
      width: '800px',
      data: {
        country: this.selectedCountry,
      },
      direction: this.direction,
    });
    dialogRefOrderChat.afterClosed().subscribe({
      next: () => {
        this.withdrawalRequested.emit();
      },
    });
  }
}
