import { NgClass, NgFor, NgIf } from '@angular/common';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { TranslateModule } from '@ngx-translate/core';
import { WalletTransactionType } from 'src/app/core/domain/wallet/wallet.model';
import { Currency } from '../../shared/interfaces/countries';
import { CurrencyShortNamePipe } from '../../shared/pipes/currency-short-name.pipe';
import { CurrencyTranslatePipe } from '../../shared/pipes/currency-translate.pipe';
import { DateWrapperUtility } from '../../shared/utilities/date-wrapper.utility';

@Component({
  selector: 'app-wallet-transactions-history-filters',
  standalone: true,
  imports: [
    TranslateModule,
    NgIf,
    NgFor,
    FormsModule,
    ReactiveFormsModule,
    CurrencyTranslatePipe,
    CurrencyShortNamePipe,
    MatDatepickerModule,
    MatFormFieldModule,
    MatSelectModule,
    MatInputModule,
    NgClass,
  ],
  templateUrl: './wallet-transactions-history-filters.component.html',
  styleUrls: ['./wallet-transactions-history-filters.component.scss'],
})
export class WalletTransactionsHistoryFiltersComponent implements OnInit {
  @Input() transactionsHistoryFilterForm: FormGroup<{
    currency: FormControl<string>;
    type: FormControl<string>;
    fromDate: FormControl<string>;
    toDate: FormControl<string>;
  }>;

  @Input() currenciesOptions: Currency[];

  public transactionsTypes: { text: string; value: string }[];

  public minDate: Date;

  public maxDate: Date;

  @Output() refreshWalletTransactionsEmitter = new EventEmitter<void>();

  @Output() downloadExcelSheetEmitter = new EventEmitter<void>();

  ngOnInit(): void {
    this._setTransactionTypes();
    this._setMinAndMaxDates();
  }

  public refreshWalletTransactions(): void {
    this.refreshWalletTransactionsEmitter.emit();
  }

  public onDownloadExcel(): void {
    this.downloadExcelSheetEmitter.emit();
  }

  public onDateFieldKeyUp($event: KeyboardEvent): void {
    if (this._isEventACommandOrNavigation($event)) {
      return;
    }
    if (!DateWrapperUtility.isDateIsAllowedFormat($event.key)) {
      $event.preventDefault();
      $event.stopImmediatePropagation();
      $event.stopPropagation();
    }
  }

  private _isEventACommandOrNavigation($event: KeyboardEvent): boolean {
    return (
      ($event.ctrlKey && $event.key === 'a') ||
      ($event.ctrlKey && $event.key === 'z') ||
      ($event.ctrlKey && $event.key === 'y') ||
      ['Backspace', 'Delete', 'ArrowLeft', 'ArrowRight'].indexOf($event.key) !== -1
    );
  }

  private _setTransactionTypes(): void {
    const defaultTransactionsType = {
      text: 'WALLET.TRANSACTION_HISTORY.TYPE_DROPDOWN.ALL',
      value: '',
    };
    const types = Object.values(WalletTransactionType).map((transactionType) => ({
      value: transactionType,
      text: `WALLET.TRANSACTION_HISTORY.TYPE_DROPDOWN.${transactionType.toUpperCase()}`,
    }));
    this.transactionsTypes = [defaultTransactionsType, ...types];
  }

  private _setMinAndMaxDates(): void {
    this.minDate = new Date(2023, 3, 1);
    this.maxDate = DateWrapperUtility.getTheEndOfATimeUnit(new Date(), 'day');
  }
}
